<template>
   <div>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
         <v-card>
            <v-toolbar elevation="2" color="primary" dense>
               <v-spacer></v-spacer>
               <v-btn icon @click="close" small class="mr-1">
                  <v-icon small>mdi-close</v-icon>
               </v-btn>
            </v-toolbar>
            <v-card-text class="px-0">
               <Reporte :init="null" :signable="false" ref="reporte" />
            </v-card-text>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import Reporte from "../../Reporte/Reporte.vue";

export default {
   components: {
      Reporte,
   },
   data: function () {
      return {
         token: "",
         dialog: false,
      };
   },
   methods: {
      close: function () {
         this.dialog = false;
      },
      show: function (id) {
         this.dialog = true;
         this.token = id;
         this.$nextTick(() => {
            this.$refs.reporte.show(id);
         });
      },
   },
};
</script>
