<template>
   <v-dialog v-model="show" max-width="600px">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>Exportar a excel</v-card-title>
         <v-card-text>
            <v-row>
               <v-col>
                  <v-select
                     v-model="report_type"
                     :items="report_types"
                     item-value="value"
                     item-text="text"
                     label="Tipo de reporte"
                     outlined
                     hide-details
                  />
               </v-col>
            </v-row>
            <v-row>
               <v-col cols="12" md="6">
                  <v-menu
                     v-model="show_init"
                     :close-on-content-click="false"
                     :nudge-right="40"
                     transition="scale-transition"
                     offset-y
                     min-width="auto"
                  >
                     <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                           v-model="init"
                           label="Fecha inicial"
                           append-icon="mdi-calendar"
                           readonly
                           v-bind="attrs"
                           v-on="on"
                           @input="
                              () => {
                                 input();
                                 $v.init.$touch();
                              }
                           "
                           :error-messages="initErrors"
                           required
                           @blur="$v.init.$touch()"
                        ></v-text-field>
                     </template>
                     <v-date-picker v-model="init" @input="show_init = false"></v-date-picker>
                  </v-menu>
               </v-col>
               <v-col cols="12" md="6">
                  <v-menu
                     v-model="show_final"
                     :close-on-content-click="false"
                     :nudge-right="40"
                     transition="scale-transition"
                     offset-y
                     min-width="auto"
                  >
                     <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                           v-model="final"
                           label="Fecha final"
                           append-icon="mdi-calendar"
                           readonly
                           v-bind="attrs"
                           v-on="on"
                           @input="
                              () => {
                                 input();
                                 $v.final.$touch();
                              }
                           "
                           :error-messages="finalErrors"
                           required
                           @blur="$v.final.$touch()"
                        ></v-text-field>
                     </template>
                     <v-date-picker v-model="final" @input="show_final = false"></v-date-picker>
                  </v-menu>
               </v-col>
            </v-row>
            <v-row>
               <v-col cols="12" md="6">
                  <v-autocomplete
                     v-model="cliente"
                     label="Cliente"
                     :loading="search_loading"
                     :search-input.sync="search_cliente"
                     :items="search_items"
                     item-text="nombre"
                     item-value="id_cliente"
                     auto-select-first
                     return-object
                     outlined
                     ref="autocomplete"
                  ></v-autocomplete>
               </v-col>
               <v-col cols="12" md="6">
                  <v-autocomplete
                     v-model="usuario"
                     label="Usuario"
                     :loading="search_loading_usuario"
                     :search-input.sync="search_usuario"
                     :items="search_items_usuario"
                     item-text="nombre"
                     item-value="id"
                     auto-select-first
                     return-object
                     outlined
                     ref="autocomplete_usuario"
                  ></v-autocomplete>
               </v-col>
            </v-row>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogResult_Cancel">Cancelar</v-btn>
            <v-btn color="primary" @click="dialogResult_OK" :loading="isloading">Aceptar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { saveAs } from "file-saver";
import moment from "moment";
import timezone from "moment-timezone";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      init: {
         date: function () {
            if (this.init.trim().length > 0) {
               if (moment(this.init).isValid()) {
                  return true;
               }
            }
            return false;
         },
         required,
      },
      final: {
         date: function () {
            if (this.final.trim().length > 0) {
               if (moment(this.final).isValid()) {
                  return true;
               }
            }
            return false;
         },
         required,
      },
   },
   data: () => {
      return {
         search_cliente: "",
         search_loading: false,
         search_items: [],
         cliente: {
            id_cliente: null,
            nombre: "",
            empresa: "",
            tipo_cobro: "H",
            horas: 0,
            telefono: "",
            email: "",
         },
         search_usuario: "",
         search_loading_usuario: false,
         search_items_usuario: [],
         usuario: {
            id: null,
            nombre: "",
            username: "",
         },
         init: "",
         final: "",
         report_type: "N",
         report_types: [
            {
               value: "N",
               text: "Normal",
            },
            {
               value: "C",
               text: "Desglosado por Clientes",
            },
            {
               value: "GC",
               text: "Agrupado por Clientes",
            },
            {
               value: "GU",
               text: "Agrupado por Usuarios",
            },
         ],
         show: false,
         show_init: false,
         show_final: false,
         isloading: false,
      };
   },
   computed: {
      initErrors() {
         const errors = [];
         if (!this.$v.init.$dirty) return errors;
         !this.$v.init.required && errors.push("Requerido");
         !this.$v.init.date && errors.push("Fecha no valida");
         return errors;
      },
      finalErrors() {
         const errors = [];
         if (!this.$v.final.$dirty) return errors;
         !this.$v.final.required && errors.push("Requerido");
         !this.$v.final.date && errors.push("Fecha no valida");
         return errors;
      },
   },
   watch: {
      search_cliente: function () {
         this.get_search_clientes();
      },
      search_usuario: function () {
         this.get_search_usuarios();
      },
   },
   created: function () {
      this.init = moment().subtract(5, "days").format("YYYY-MM-DD");
      this.final = moment().format("YYYY-MM-DD");
   },
   methods: {
      dialogResult_OK: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("getReportesExcel", {
                  init: this.init,
                  final: this.final,
                  type: this.report_type,
                  id_cliente: this.cliente.id_cliente,
                  id_user: this.usuario.id,
                  TZ: timezone.tz.guess(),
               });
               if (res) {
                  saveAs(res.data, "Movimientos-" + this.init + "-" + this.final + ".xlsx");
                  this.show = false;
                  this.$emit("result_ok");
               } else {
                  throw res;
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      dialogResult_Cancel: function () {
         this.show = false;
         this.$emit("result_cancel");
      },
      Show() {
         this.show = true;
      },
      get_search_clientes: async function () {
         try {
            if (this.search_cliente) {
               if (this.search_cliente.trim().length > 0) {
                  this.search_loading = true;
                  let res = await this.$store.dispatch("getFastClientes", {
                     search: this.search_cliente,
                  });
                  if (res == true) {
                     this.search_items = this.$store.state.fast_clientes;
                  } else {
                     throw res
                  }
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.search_loading = false;
         }
      },
      get_search_usuarios: async function () {
         try {
            if (this.search_usuario) {
               if (this.search_usuario.trim().length > 0) {
                  this.search_loading_usuario = true;
                  let res = await this.$store.dispatch("getFastUsuarios", {
                     search: this.search_usuario,
                  });
                  if (res == true) {
                     this.search_items_usuario = this.$store.state.fast_usuarios;
                  } else {
                     throw res;
                  }
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.search_loading_usuario = false;
         }
      },
   },
};
</script>
