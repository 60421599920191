<template>
   <v-dialog v-model="show" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="error" message="Debe indicar un correo o un telefono" ref="error_or_noti" />
      <v-card :loading="isloading">
         <v-card-title>Reenviar reporte {{ report.id_reporte }}</v-card-title>
         <v-card-text>
            <v-text-field
               v-model="telefono"
               label="Telefono"
               :error-messages="telefonoErrors"
               required
               clearable
               @input="$v.telefono.$touch()"
               @blur="$v.telefono.$touch()"
            ></v-text-field>
            <v-text-field
               v-model="email"
               label="Email"
               :error-messages="emailErrors"
               required
               clearable
               @input="$v.email.$touch()"
               @blur="$v.email.$touch()"
            ></v-text-field>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogResult_Cancel">Cancelar</v-btn>
            <v-btn color="primary" @click="dialogResult_OK" :loading="isloading">Aceptar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import Notification from "../../Notification.vue";
import { validationMixin } from "vuelidate";
import { required, email, minLength, maxLength, integer } from "vuelidate/lib/validators";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      telefono: {
         maxLength: maxLength(10),
         minLength: minLength(10),
         integer,
      },
      email: { email, maxLength: maxLength(320) },
   },
   data: () => {
      return {
         report: {},
         email: "",
         telefono: "",
         show: false,
         isloading: false,
      };
   },
   computed: {
      telefonoErrors() {
         const errors = [];
         if (!this.$v.telefono.$dirty) return errors;
         !this.$v.telefono.minLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.maxLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.integer && errors.push("Debe contener caracteres numericos");
         return errors;
      },
      emailErrors() {
         const errors = [];
         if (!this.$v.email.$dirty) return errors;
         !this.$v.email.email && errors.push("Debe ser un email valido");
         !this.$v.email.maxLength && errors.push("El maximo posible es de 320");
         return errors;
      },
   },
   methods: {
      dialogResult_OK: async function () {
         try {
            if (!this.telefono && !this.email) {
               this.$refs.error_or_noti.Show();
               return;
            }
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("reenviarReporte", {
                  id_reporte: this.report.id_reporte,
                  id_cliente: this.report.id_cliente,
                  email: this.email,
                  telefono: this.telefono,
               });
               if (res) {
                  this.show = false;
                  this.$emit("result_ok");
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      dialogResult_Cancel: function () {
         this.show = false;
      },
      Show(reporte) {
         this.report = reporte;
         this.email = reporte.email;
         this.telefono = reporte.telefono;
         this.show = true;
      },
   },
};
</script>
