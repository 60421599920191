<template>
   <div>
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="success" message="Reporte enviado" ref="ok_report_resend" />
      <Notification type="warning" message="Guardado" ref="saved_with_error_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="error" message="Acceso denegado" ref="denied_notification" />
      <Notification type="error" message="Solo los subusuarios pueden crear reportes" ref="denied_subuser_notification" />
      <Dialog ref="delete_dialog" message="¿Cancelar reporte?" @result_ok="confirmDelete" />
      <ExportExcel ref="excelexport" />
      <Report ref="report" @save="report_saved" @save_with_error="report_saved_with_error" />
      <Viewer ref="viewer" />
      <Resend ref="resend" @result_ok="$refs.ok_report_resend.Show()" />
      <v-data-table
         :headers="headers"
         :item-key="'id_reporte'"
         :items="rows"
         :item-class="itemRowBackground"
         :options.sync="options"
         :expanded.sync="expanded"
         :loading="loading"
         :page.sync="page"
         :server-items-length="totalItems"
         :footer-props="{
            'items-per-page-options': [10, 30, 50, 100],
         }"
         class="ma-3"
         single-expand
         show-expand
      >
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn color="primary" @click="newItem" class="d-none d-sm-flex mr-2"> <v-icon left>mdi-plus-circle</v-icon>Nuevo Reporte</v-btn>
               <v-btn color="accent" @click="newItem" class="d-flex d-sm-none mr-2" icon small><v-icon small>mdi-plus-circle</v-icon></v-btn>
               <v-btn color="primary" @click="$refs.excelexport.Show()" class="d-none d-sm-flex mr-2">
                  <v-icon left>mdi-microsoft-excel</v-icon>Exportar excel
               </v-btn>
               <v-btn color="accent" @click="$refs.excelexport.Show()" class="d-flex d-sm-none mr-2" icon small
                  ><v-icon small>mdi-microsoft-excel</v-icon></v-btn
               >
               <v-btn color="accent" @click="initialize" icon small class="mr-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                  @input="search = search.slice(0, 30)"
               ></v-text-field>
            </v-toolbar>
            <div class="pa-2 d-flex justify-start align-center" v-if="filter_cliente.length > 0 || filter_user.length > 0">
               <v-chip @click:close="filter_user = ''" class="mr-1" v-if="filter_user.length > 0" small close close-icon="mdi-close">{{
                  display_chip(filter_user, 20)
               }}</v-chip>
               <v-chip @click:close="filter_cliente = ''" v-if="filter_cliente.length > 0" small close close-icon="mdi-close">{{
                  display_chip(filter_cliente, 20)
               }}</v-chip>
            </div>
         </template>
         <template v-slot:[`item.fecha_hora`]="{ item }">
            {{ displayDate(item.fecha_hora) }}
         </template>
         <template v-slot:[`item.usuario`]="{ item }">
            <div class="d-flex justify-space-between">
               {{ item.usuario }}
               <v-btn icon x-small @click="filter_user = item.usuario">
                  <v-icon x-small> mdi-filter </v-icon>
               </v-btn>
            </div>
         </template>
         <template v-slot:[`item.cliente`]="{ item }">
            <div class="d-flex justify-space-between">
               {{ item.cliente }}
               <v-btn icon x-small @click="filter_cliente = item.cliente">
                  <v-icon x-small> mdi-filter </v-icon>
               </v-btn>
            </div>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item.cancelado == 'N'">
               <v-icon small class="mr-2" @click="openReport(item)">mdi-eye</v-icon>
               <v-icon small class="mr-2" @click="copy(item)">mdi-content-copy</v-icon>
               <v-icon small class="mr-2" @click="reenviarReporte(item)">mdi-send</v-icon>
               <v-icon small class="mr-2" @click="cancelReporte(item)">mdi-cancel</v-icon>
            </div>
         </template>
         <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="expand-container">
               <v-container style="margin: 0; max-width: unset">
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Descripcion</v-col>
                     <v-col class="expand-content">{{ item.descripcion }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Tipo cobro</v-col>
                     <v-col class="expand-content">
                        <v-icon small v-if="item.tipo_cobro == 'I'" color="success">mdi-currency-usd</v-icon>
                        <v-icon small v-if="item.tipo_cobro == 'H'" color="warning">mdi-clock-outline</v-icon>
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Importe Horas</v-col>
                     <v-col class="expand-content">{{ item.importe_horas }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Notas</v-col>
                     <v-col class="expand-content">{{ item.notas }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Solicitó</v-col>
                     <v-col class="expand-content">{{ item.solicito }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="3" md="2" lg="1" class="mr-4 expand-header">Firmado</v-col>
                     <v-col class="expand-content">
                        <v-icon small v-if="item.firmados == 'S'">mdi-check-bold</v-icon>
                        <v-icon small v-if="item.firmados == 'N'">mdi-close-thick</v-icon>
                     </v-col>
                  </v-row>
               </v-container>
            </td>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Report from "./Reports/Report.vue";
import ExportExcel from "./Reports/ExportExcel.vue";
import Notification from "../Notification.vue";
import Dialog from "../Dialog.vue";
import Resend from "./Reports/Resend.vue";
import Viewer from "./Reports/Viewer.vue";
import timezone from "moment-timezone";
import moment from "moment";
import c from "../../const";

export default {
   components: {
      Report,
      ExportExcel,
      Notification,
      Viewer,
      Dialog,
      Resend,
   },
   data: () => ({
      headers: [
         { text: "ID", value: "id_reporte" },
         { text: "Usuario", value: "usuario" },
         { text: "Cliente", value: "cliente" },
         { text: "Empresa", value: "empresa" },
         { text: "Folio", value: "folio" },
         { text: "Fecha Hora", value: "fecha_hora" },
         { text: "Actions", value: "actions" },
         { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      rows: [],
      options: {
         page: 1,
         itemsPerPage: 10,
         sortBy: ["id_reporte"],
         sortDesc: [true],
      },
      search: "",
      loading: false,
      totalItems: 0,
      page: 1,
      filter_user: "",
      filter_cliente: "",
      deleteItem: {},
   }),
   watch: {
      options: async function () {
         await this.initialize();
      },
      search: async function () {
         this.page = 1;
         await this.initialize();
      },
      filter_user: async function () {
         this.page = 1;
         await this.initialize();
      },
      filter_cliente: async function () {
         this.page = 1;
         await this.initialize();
      },
   },
   methods: {
      async initialize() {
         try {
            this.loading = true;
            await this.$store.dispatch("getReportes", {
               page: this.options.page,
               itemsPerPage: this.options.itemsPerPage,
               sortBy: this.options.sortBy[0] ?? "",
               sortDesc: this.options.sortDesc[0] ?? false,
               search: this.search,
               filter_user: this.filter_user,
               filter_cliente: this.filter_cliente,
               TZ: timezone.tz.guess(),
            });
            this.rows = this.$store.state.reportes.reports;
            this.totalItems = this.$store.state.reportes.totalItems;
            localStorage.setItem("reportes_options", JSON.stringify(this.options));
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.loading = false;
         }
      },
      async newItem() {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.CREAR_REPORTES]);
         if (has_permiso == true) {
            if (this.$store.getters.isSubUser == true) {
               this.$refs.report.show();
            } else {
               this.$refs.denied_subuser_notification.Show();
            }
         } else {
            this.$refs.denied_notification.Show();
         }
      },
      async cancelReporte(item) {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.CANCELAR_REPORTES]);
         if (has_permiso == true) {
            this.deleteItem = item;
            this.$refs.delete_dialog.Show();
         } else {
            this.$refs.denied_notification.Show();
         }
      },
      async reenviarReporte(item) {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.REENVIAR_REPORTE]);
         if (has_permiso == true) {
            this.$refs.resend.Show(item);
         } else {
            this.$refs.denied_notification.Show();
         }
      },
      async confirmDelete() {
         try {
            this.loading = true;
            await this.$store.dispatch("cancelReporte", {
               id_reporte: this.deleteItem.id_reporte,
            });
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.loading = false;
            this.initialize();
         }
      },
      displayDate(val) {
         return moment(val).format("HH:mm DD/MM/YYYY");
      },
      openReport(item) {
         this.$refs.viewer.show(item.token);
      },
      async copy(item) {
         await navigator.clipboard.writeText(c.FRONTEND_URL + "r?t=" + item.token);
      },
      display_chip(str, len = 10) {
         return str.length > len ? str.substring(0, len) + "..." : str;
      },
      itemRowBackground: function (item) {
         return item.cancelado == "S" ? "cancelado" : "";
      },
      report_saved: function () {
         this.initialize();
         this.$refs.ok_noti.Show();
      },
      report_saved_with_error: function () {
         this.initialize();
         this.$refs.saved_with_error_noti.Show();
      },
   },
   created() {
      try {
         if (localStorage.getItem("reportes_options")) {
            this.options = JSON.parse(localStorage.getItem("reportes_options"));
         } else {
            this.options = {
               page: 1,
               itemsPerPage: 10,
               sortBy: ["id_reporte"],
               sortDesc: [true],
            };
            localStorage.setItem("reportes_options", JSON.stringify(this.options));
         }
      } catch (error) {
         this.options = {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["id_reporte"],
            sortDesc: [true],
         };
         localStorage.setItem("reportes_options", JSON.stringify(this.options));
      }
   },
   mounted: async function () {
      if (this.$router.currentRoute.query.status == "new") {
         this.newItem();
      }
   },
   beforeRouteUpdate(to, from, next) {
      if (to.query.status == "new") {
         this.newItem();
      }
      next();
   },
};
</script>

<style>
.expand-header {
   font-weight: 700;
}
.expand-content {
   text-align: left;
}
.cancelado {
   background-color: #da4c4c;
}
</style>
