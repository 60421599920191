<template>
   <div>
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
         <v-card :loading="isloading">
            <v-toolbar elevation="2" color="secondary" class="fixed-bar">
               <v-btn icon @click="close">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-toolbar-title>Nuevo reporte</v-toolbar-title>
               <v-spacer></v-spacer>
               <v-btn color="primary" @click="save" :loading="isloading"><v-icon left>mdi-content-save</v-icon>Guardar</v-btn>
            </v-toolbar>
            <v-card-text class="pt-6">
               <!-- <v-subheader>Cliente</v-subheader> -->
               <v-autocomplete
                  v-model="cliente"
                  label="Buscar cliente"
                  :loading="search_loading"
                  :search-input.sync="search_cliente"
                  :items="get_search_items"
                  item-text="display"
                  item-value="id_cliente"
                  auto-select-first
                  return-object
                  outlined
                  required
                  :error-messages="clienteErrors"
                  ref="autocomplete"
                  @change="search_blur"
                  @input="$v.cliente.$touch()"
                  @blur="$v.cliente.$touch()"
               ></v-autocomplete>
               <v-row no-gutters>
                  <v-col cols="6" md="4"><v-text-field class="pr-4" label="Nombre" readonly :value="nombre"></v-text-field></v-col>
                  <v-col cols="6" md="4"><v-text-field label="Empresa" readonly :value="empresa"></v-text-field></v-col>
                  <v-col class="d-flex justify-center align-center">
                     <v-icon small v-if="tipo_cobro == 'I'" color="success">mdi-currency-usd</v-icon>
                     <v-icon small v-if="tipo_cobro == 'H'" color="warning">mdi-clock-outline</v-icon>
                  </v-col>
                  <v-col v-if="tipo_cobro == 'H'"><v-text-field label="Horas" readonly :value="horas"></v-text-field></v-col>
               </v-row>
               <!-- <v-subheader>Reporte</v-subheader> -->
               <v-divider class="mb-6 mt-2"></v-divider>
               <v-textarea
                  label="Descripción"
                  outlined
                  v-model="descripcion"
                  :error-messages="descripcionErrors"
                  required
                  rows="2"
                  @input="$v.descripcion.$touch()"
                  @blur="$v.descripcion.$touch()"
               ></v-textarea>
               <v-row no-gutters>
                  <v-col cols="8" md="10">
                     <v-text-field
                        outlined
                        v-model="importe"
                        :error-messages="importeErrors"
                        label="Importe"
                        type="number"
                        steps="2"
                        requied
                        @input="$v.importe.$touch()"
                        @blur="$v.importe.$touch(), formatImporte()"
                     ></v-text-field>
                  </v-col>
                  <v-col>
                     <v-switch
                        :prepend-icon="type_cobro_icon"
                        :disabled="!can_change_tipo_cobro"
                        class="ml-4"
                        label=""
                        color="gray"
                        v-model="tipo_cobro"
                        true-value="H"
                        false-value="I"
                     ></v-switch>
                  </v-col>
               </v-row>
               <v-textarea
                  label="Notas"
                  outlined
                  v-model="notas"
                  :error-messages="notasErrors"
                  rows="2"
                  @input="$v.notas.$touch()"
                  @blur="$v.notas.$touch()"
               ></v-textarea>
               <v-text-field
                  label="Solicitó"
                  outlined
                  v-model="solicito"
                  :error-messages="solicitoErrors"
                  @input="$v.solicito.$touch()"
                  @blur="$v.solicito.$touch()"
               ></v-text-field>
               <v-card
                  @drop.prevent="onDrop($event)"
                  @dragover.prevent="dragover = true"
                  @dragenter.prevent="dragover = true"
                  @dragleave.prevent="dragover = false"
                  @paste="onPaste($event)"
                  tabindex="-1"
                  class="mb-2"
               >
                  <v-card-text>
                     <v-file-input
                        v-model="files"
                        label="Imagenes"
                        outlined
                        chips
                        counter
                        multiple
                        show-size
                        truncate-length="15"
                        :error-messages="filesErrors"
                        @input="$v.files.$touch()"
                        @blur="$v.files.$touch()"
                     ></v-file-input>
                  </v-card-text>
               </v-card>
               <!-- <v-subheader>Envío</v-subheader> -->
               <v-divider class="mb-4 mt-6"></v-divider>
               <v-row>
                  <v-col cols="12" md="3" class="d-flex">
                     <v-checkbox class="mr-6" label="Incluir SMS" v-model="include_sms"></v-checkbox>
                     <v-checkbox label="Incluir EMAIL" v-model="include_email"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field
                        v-model="telefono"
                        :disabled="!can_change_telefono || !include_sms"
                        :error-messages="telefonoErrors"
                        :counter="10"
                        label="Telefono"
                        required
                        @input="$v.telefono.$touch()"
                        @blur="$v.telefono.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5">
                     <v-text-field
                        v-model="email"
                        :disabled="!can_change_email"
                        :error-messages="emailErrors"
                        label="Email"
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </v-card-text>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email, minLength, integer } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";
import c from "../../../const";
import moment from "moment";
import timezone from "moment-timezone";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      cliente: {
         valid: function () {
            if (this.id_cliente) {
               return true;
            } else {
               return false;
            }
         },
      },
      telefono: {
         maxLength: maxLength(10),
         minLength: minLength(10),
         integer,
      },
      email: { email, maxLength: maxLength(320) },
      descripcion: { maxLength: maxLength(1200), required },
      importe: {
         required,
         isNumber: function () {
            return !isNaN(this.importe);
         },
         absNum: function () {
            return this.importe > 0;
         },
      },
      notas: { maxLength: maxLength(1000) },
      solicito: { maxLength: maxLength(50) },
      files: {
         max: function () {
            return this.files.length <= c.REPORTE_MAX_IMGS;
         },
         file_size: function () {
            let res = true;
            this.files.forEach((element) => {
               if (element.size > 1000000) {
                  res = false;
               }
            });
            return res;
         },
         file_type: function () {
            let res = true;
            this.files.forEach((element) => {
               if (element.type != "image/jpeg" && element.type != "image/jpg" && element.type != "image/png") {
                  res = false;
               }
            });
            return res;
         },
      },
   },
   data: function () {
      return {
         dragover: false,
         search_cliente: "",
         search_loading: false,
         search_items: [],
         cliente: {
            id_cliente: null,
            nombre: "",
            empresa: "",
            tipo_cobro: "H",
            horas: 0,
            telefono: "",
            email: "",
            display: "",
         },
         id_cliente: null,
         nombre: "",
         empresa: "",
         horas: 0,
         descripcion: "",
         importe: 0,
         tipo_cobro: "H",
         notas: "",
         solicito: "",
         files: [],
         telefono: "",
         email: "",
         include_sms: false,
         include_email: true,
         dialog: false,
         isloading: false,
         can_change_tipo_cobro: false,
         can_change_telefono: false,
         can_change_email: false,
      };
   },
   watch: {
      search_cliente: function () {
         this.get_search_clientes();
      },
   },
   mounted: async function () {
      try {
         this.isloading = true;
         if (this.$router.currentRoute.query.id_cliente) {
            let res = await this.$store.dispatch("getClienteByID", {
               id: this.$router.currentRoute.query.id_cliente,
            });
            if (res) {
               this.id_cliente = res.id_cliente;
               this.nombre = res.nombre;
               this.empresa = res.empresa;
               this.tipo_cobro = res.tipo_cobro;
               this.horas = res.horas;
               this.telefono = res.telefono;
               this.email = res.email;
            }
         }
         if (this.$router.currentRoute.query.descripcion) {
            this.descripcion = this.$router.currentRoute.query.descripcion;
         }
         if (this.$router.currentRoute.query.horas) {
            this.importe = this.$router.currentRoute.query.horas;
         }
      } catch (error) {
         this.$refs.error_noti.ShowPersistent(error);
      } finally {
         this.isloading = false;
      }
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("insertReporte", {
                  id_cliente: this.id_cliente,
                  descripcion: this.descripcion,
                  importe: this.importe,
                  tipo_cobro: this.tipo_cobro,
                  notas: this.notas,
                  solicito: this.solicito,
                  files: this.files,
                  telefono: this.telefono,
                  email: this.include_email == true ? this.email : "",
                  includeSMS: this.include_sms == true ? "S" : "N",
                  TZ: timezone.tz.guess(),
               });
               if (res == true) {
                  this.$refs.ok_noti.Show();
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            switch (error.status) {
               case 441:
                  this.$emit("save_with_error");
                  this.close();
                  break;
               default:
                  this.$refs.error_noti.ShowPersistent(error);
                  break;
            }
         } finally {
            this.isloading = false;
         }
      },
      get_search_clientes: async function () {
         try {
            if (this.search_cliente) {
               if (this.search_cliente.trim().length > 0) {
                  this.search_loading = true;
                  let res = await this.$store.dispatch("getFastClientes", {
                     search: this.search_cliente,
                  });
                  if (res == true) {
                     this.search_items = this.$store.state.fast_clientes;
                  } else {
                     throw res;
                  }
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.search_loading = false;
         }
      },
      search_blur: function () {
         try {
            this.id_cliente = this.cliente.id_cliente;
            this.nombre = this.cliente.nombre;
            this.empresa = this.cliente.empresa;
            this.tipo_cobro = this.cliente.tipo_cobro;
            this.horas = this.cliente.horas;
            this.telefono = this.cliente.telefono;
            this.email = this.cliente.email;
            this.$refs.autocomplete.blur();
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: async function () {
         this.can_change_tipo_cobro = await this.$store.dispatch("checkPermiso", [c.PERMISOS.CAMBIAR_TIPO_COBRO]);
         this.can_change_telefono = await this.$store.dispatch("checkPermiso", [c.PERMISOS.EDITAR_TELEFONO_AL_ENVIO]);
         this.can_change_email = await this.$store.dispatch("checkPermiso", [c.PERMISOS.EDITAR_CORREO_AL_ENVIO]);
         this.dialog = true;

         this.search_cliente = "";
         this.search_loading = false;
         this.search_items = [];
         this.cliente = {
            id_cliente: null,
            nombre: "",
            empresa: "",
            tipo_cobro: "H",
            horas: 0,
            telefono: "",
            email: "",
            display: "",
         };
         this.id_cliente = null;
         this.nombre = "";
         this.empresa = "";
         this.horas = 0;
         this.descripcion = "";
         this.importe = 0;
         this.tipo_cobro = "H";
         this.notas = "";
         this.solicito = "";
         this.files = [];
         this.telefono = "";
         this.email = "";
         this.include_sms = false;

         this.$v.$reset();
      },
      onDrop: function (e) {
         if (this.files.length > 0) this.files = [];
         Array.from(e.dataTransfer.files).forEach((element) => {
            this.files.push(element);
         });
      },
      onPaste: function (e) {
         if (this.files.length > 0) this.files = [];
         Array.from(e.clipboardData.files).forEach((element) => {
            this.files.push(element);
         });
      },
      formatImporte: function () {
         this.importe = parseFloat(this.importe);
      },
   },
   computed: {
      type_cobro_icon() {
         return this.tipo_cobro == "H" ? "mdi-clock-outline" : "mdi-currency-usd";
      },
      get_search_items() {
         try {
            let items = [];
            this.search_items.forEach((element) => {
               items.push({
                  id_cliente: element.id_cliente,
                  nombre: element.nombre,
                  empresa: element.empresa,
                  tipo_cobro: element.tipo_cobro,
                  horas: element.horas,
                  telefono: element.telefono,
                  email: element.email,
                  display: element.nombre,
               });
            });
            return items;
         } catch (error) {}
      },
      clienteErrors() {
         const errors = [];
         if (!this.$v.cliente.$dirty) return errors;
         !this.$v.cliente.valid && errors.push("El cliente es requerido");
         return errors;
      },
      descripcionErrors() {
         const errors = [];
         if (!this.$v.descripcion.$dirty) return errors;
         !this.$v.descripcion.maxLength && errors.push("Debe contener maximo 255 caracteres");
         !this.$v.descripcion.required && errors.push("Requerido");
         return errors;
      },
      importeErrors() {
         const errors = [];
         if (!this.$v.importe.$dirty) return errors;
         !this.$v.importe.required && errors.push("Requerido");
         !this.$v.importe.absNum && errors.push("Debe ser un valor positivo");
         !this.$v.importe.isNumber && errors.push("Debe ser un numero valido");
         return errors;
      },
      notasErrors() {
         const errors = [];
         if (!this.$v.notas.$dirty) return errors;
         !this.$v.notas.maxLength && errors.push("Debe contener maximo 1000 caracteres");
         return errors;
      },
      solicitoErrors() {
         const errors = [];
         if (!this.$v.solicito.$dirty) return errors;
         !this.$v.solicito.maxLength && errors.push("Debe contener maximo 50 caracteres");
         return errors;
      },
      filesErrors() {
         const errors = [];
         if (!this.$v.files.$dirty) return errors;
         !this.$v.files.max && errors.push("Debe contener maximo " + c.REPORTE_MAX_IMGS + " archivos");
         !this.$v.files.file_size && errors.push("Uno o mas archivos son mayores a 1MB");
         !this.$v.files.file_type && errors.push("Uno o mas archivos no son de tipo imagen");
         return errors;
      },
      telefonoErrors() {
         const errors = [];
         if (!this.$v.telefono.$dirty) return errors;
         !this.$v.telefono.minLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.maxLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.integer && errors.push("Debe contener caracteres numericos");
         return errors;
      },
      emailErrors() {
         const errors = [];
         if (!this.$v.email.$dirty) return errors;
         !this.$v.email.email && errors.push("Debe ser un email valido");
         !this.$v.email.maxLength && errors.push("El maximo posible es de 320");
         return errors;
      },
   },
};
</script>

<style scoped>
.fixed-bar {
   position: sticky;
   position: -webkit-sticky; /* for Safari */
   top: 0;
   z-index: 2;
}
</style>
