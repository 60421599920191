<template>
   <Reports/>
</template>

<script>
import Reports from "../components/Reportes/Reports.vue"
export default {
   name: "Reportes",
   components:{
      Reports,
   }
};
</script>
