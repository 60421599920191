var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('Notification',{ref:"ok_noti",attrs:{"type":"success","message":"Información actualizada"}}),_c('Notification',{ref:"error_noti",attrs:{"type":"error","message":"Error"}}),_c('v-card',{attrs:{"loading":_vm.isloading}},[_c('v-card-title',[_vm._v("Exportar a excel")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.report_types,"item-value":"value","item-text":"text","label":"Tipo de reporte","outlined":"","hide-details":""},model:{value:(_vm.report_type),callback:function ($$v) {_vm.report_type=$$v},expression:"report_type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha inicial","append-icon":"mdi-calendar","readonly":"","error-messages":_vm.initErrors,"required":""},on:{"input":() => {
                              _vm.input();
                              _vm.$v.init.$touch();
                           },"blur":function($event){return _vm.$v.init.$touch()}},model:{value:(_vm.init),callback:function ($$v) {_vm.init=$$v},expression:"init"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_init),callback:function ($$v) {_vm.show_init=$$v},expression:"show_init"}},[_c('v-date-picker',{on:{"input":function($event){_vm.show_init = false}},model:{value:(_vm.init),callback:function ($$v) {_vm.init=$$v},expression:"init"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha final","append-icon":"mdi-calendar","readonly":"","error-messages":_vm.finalErrors,"required":""},on:{"input":() => {
                              _vm.input();
                              _vm.$v.final.$touch();
                           },"blur":function($event){return _vm.$v.final.$touch()}},model:{value:(_vm.final),callback:function ($$v) {_vm.final=$$v},expression:"final"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_final),callback:function ($$v) {_vm.show_final=$$v},expression:"show_final"}},[_c('v-date-picker',{on:{"input":function($event){_vm.show_final = false}},model:{value:(_vm.final),callback:function ($$v) {_vm.final=$$v},expression:"final"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{ref:"autocomplete",attrs:{"label":"Cliente","loading":_vm.search_loading,"search-input":_vm.search_cliente,"items":_vm.search_items,"item-text":"nombre","item-value":"id_cliente","auto-select-first":"","return-object":"","outlined":""},on:{"update:searchInput":function($event){_vm.search_cliente=$event},"update:search-input":function($event){_vm.search_cliente=$event}},model:{value:(_vm.cliente),callback:function ($$v) {_vm.cliente=$$v},expression:"cliente"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{ref:"autocomplete_usuario",attrs:{"label":"Usuario","loading":_vm.search_loading_usuario,"search-input":_vm.search_usuario,"items":_vm.search_items_usuario,"item-text":"nombre","item-value":"id","auto-select-first":"","return-object":"","outlined":""},on:{"update:searchInput":function($event){_vm.search_usuario=$event},"update:search-input":function($event){_vm.search_usuario=$event}},model:{value:(_vm.usuario),callback:function ($$v) {_vm.usuario=$$v},expression:"usuario"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.dialogResult_Cancel}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isloading},on:{"click":_vm.dialogResult_OK}},[_vm._v("Aceptar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }